import { CurrencyProvider } from 'contexts/Currency'
import { GDPRPopup } from './domains/App/components'
import { GoogleOAuthProvider } from '@react-oauth/google'
import Navigator from './pages/Navigator'
import { PlanProvider } from 'domains/Plan/context'
import { StripeProvider } from 'modules/stripe-module/contexts/Stripe'
import { ThemeProvider } from 'styled-components'
import { TranslationsProvider } from 'contexts/Translation'
import breakpoints from 'styles/app/breakpoints'

/**
 * It returns a React component that renders a TranslationsProvider and a Navigator.
 * @returns The <TranslationsProvider> component.
 */
function App() {
  return (
    <ThemeProvider theme={breakpoints}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}>
        <TranslationsProvider>
          <StripeProvider>
            <PlanProvider>
              <CurrencyProvider>
                <Navigator />
                <GDPRPopup />
              </CurrencyProvider>
            </PlanProvider>
          </StripeProvider>
        </TranslationsProvider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  )
}

export default App
