import { Button, Img, Text } from '@qonsoll/react-design'
import { Popconfirm, notification } from 'antd'

import PropTypes from 'prop-types'
import nordneaLogo from 'assets/Nordea_logo_1_80x18.png'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUpdateUserPhoneNumberModal } from 'domains/User/hooks'
import { useUser } from 'domains/User/context'

const LoanApplicationButton = (props) => {
  const { projectName, form, isLoanSendMode, download } = props

  const [isLoanPopConfirmOpened, setIsLoanPopConfirmOpened] = useState(false)

  const { t } = useTranslations()
  const { isProAccess, user } = useUser()

  const openPhoneNumberModal = useUpdateUserPhoneNumberModal()

  const onLoanApplicationClick = () => {
    if (!projectName) {
      notification.error({
        message: t('Project name should be entered to send loan application')
      })
      return
    }
    if (!form.getFieldValue(['rentalCalculator', 'askingPrice'])) {
      notification.error({
        message: t('Asking price should be entered to send loan application')
      })
      return
    }
    if (!form.getFieldValue(['rentalCalculator', 'rentalIncome'])) {
      notification.error({
        message: t('Rental income should be entered to send loan application')
      })
      return
    }
    if (!form.getFieldValue(['rentalCalculator', 'downPayment'])) {
      notification.error({
        message: t('Down payment should be entered to send loan application')
      })
      return
    }
    if (!user?.firstName || !user?.lastName) {
      notification.error({
        message: !projectName
          ? `${t('User first name is required to send loan application')}.${t(
              'Enter and save your first name in My account page'
            )}`
          : `${t('User last name is required to send loan application')}.${t(
              'Enter and save your last name on My account page'
            )}`
      })
      return
    }
    if (!user?.phone) {
      openPhoneNumberModal()
      return
    }
    setIsLoanPopConfirmOpened(true)
  }

  return (
    <Popconfirm
      title={
        <div>
          <div style={{ width: '350px' }}>
            <div>
              <Text strong>{t('Finansiering')}</Text>
            </div>
            <Text>
              {`${t('Søk finansiering hos vår samarbeidspartner')}, ${t(
                'Nordea'
              )}. ${t(
                'Send budsjett for dette utleieobjektet direkte til banken for en rask vurdering'
              )}. ${t(
                'Når skjema er innsendt vil du bli videresendt til Nordea’s side for samtykke'
              )}. ${t(
                'Det er viktig at du logger deg inn og gir samtykke slik at Nordea kan behandle din søknad'
              )}. ${t('Du vil så bli kontaktet direkte av rådgiver')}.`}
            </Text>
          </div>
          <Img
            style={{ position: 'absolute', bottom: '-20px' }}
            alt="Nordnea logo"
            src={nordneaLogo}
          />
        </div>
      }
      visible={isLoanPopConfirmOpened}
      onConfirm={() => {
        setIsLoanPopConfirmOpened(false)
        download()
        window.open(
          process.env.REACT_APP_BANK_CONSENT_URL,
          '_blank',
          'width=500,height=500'
        )
      }}
      icon={null}
      okText={t('Send application')}
      cancelText={t('Cancel ')}
      onCancel={() => setIsLoanPopConfirmOpened(false)}>
      <Button
        block
        disabled={!isProAccess}
        loading={isLoanSendMode}
        onClick={onLoanApplicationClick}>
        {t('Loan application')}
      </Button>
    </Popconfirm>
  )
}
LoanApplicationButton.propTypes = {
  projectName: PropTypes.string,
  form: PropTypes.object,
  isLoanSendMode: PropTypes.bool,
  download: PropTypes.func
}
export default LoanApplicationButton
