import { Box, Button, Divider, Img, Text } from '@qonsoll/react-design'
import { useGDPRStatus, useSessionActions } from 'domains/Session/hooks'

import { GoogleAuthProvider } from 'firebase/auth'
import { LastSessionUser } from 'domains/Session/components'
import { Link } from 'react-router-dom'
import { MailOutlined } from '@ant-design/icons'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import firebase from 'firebase/compat/app'
import googleLogo from '../../../../assets/logo-google.svg'
import { notification } from 'antd'
import { useGoogleLogin } from '@react-oauth/google'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const AuthMethods = (props) => {
  const { t } = useTranslations()
  const {
    authTypeText,
    redirectToFormWithEmail,
    authSwitchText,
    authSwitchLinkText,
    authSwitchPath,
    lastSession
  } = props

  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const { getLastSessionProvider, saveUserToDB } = useSessionActions()
  const gdpr = useGDPRStatus()

  // [CLEAN_FUNCTIONS]
  const redirectToLoginWithEmail = () => {
    history.push(PATHS.UNAUTHENTICATED.LOGIN_WITH_EMAIL)
  }
  const sessionProvider = getLastSessionProvider()

  const handleError = (error) => {
    // eslint-disable-next-line no-console
    console.error('Firebase sign-in error:', error)

    notification.error({
      message: t('Error'),
      description: t('Google sign-in failed')
    })
  }

  const handleSuccess = async (tokenResponse) => {
    try {
      // Create Firebase credential
      const credential = await retrieveCredentials(tokenResponse)

      const auth = firebase.auth()
      const firestore = firebase.firestore()

      const userCredential = await auth.signInWithCredential(credential)
      const user = userCredential.user

      // Check if the user is new
      const userSnap = await firestore.collection('users').doc(user.uid).get()
      const isNew = !userSnap.exists

      if (isNew) {
        await saveUserToDB({
          gdpr,
          id: user.uid,
          email: user.email,
          avatarUrl: user.photoURL,
          agreement: true,
          additionalData: { displayName: user.displayName }
        })
      }
    } catch (error) {
      handleError(error)
    }
  }

  const retrieveCredentials = async (tokenResponse) => {
    // 🔹 Create Firebase credential
    const credential = GoogleAuthProvider.credential(
      null,
      tokenResponse.access_token
    )

    return credential
  }

  const login = useGoogleLogin({
    onSuccess: handleSuccess,
    onError: handleError
  })

  return (
    <>
      <Button
        block
        onClick={login}
        variant="white"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.2), 0px -1px 2px rgba(0, 0, 0, 0.1)">
        <Img mr={2} src={googleLogo} alt="No data" />
        Sign in with Google
      </Button>

      <Box>
        <Divider>
          <Text variant="overline" type="secondary">
            {t('Or')}
          </Text>
        </Divider>
      </Box>

      {sessionProvider === 'email' && lastSession?.email ? (
        <LastSessionUser
          lastSession={lastSession}
          onChangeAccount={redirectToLoginWithEmail}
          redirectTo={redirectToFormWithEmail}
        />
      ) : (
        <Button
          size="large"
          block
          icon={<MailOutlined />}
          onClick={redirectToFormWithEmail}>
          {authTypeText} {t('with email')}
        </Button>
      )}
      <Box display="flex" justifyContent="center" mt={24}>
        <Text type="secondary" mr={1}>
          {authSwitchText}
        </Text>
        <Link to={authSwitchPath}>{authSwitchLinkText}</Link>
      </Box>
    </>
  )
}

AuthMethods.propTypes = {
  authSwitchLinkText: PropTypes.any,
  authSwitchPath: PropTypes.any,
  authSwitchText: PropTypes.any,
  authTypeText: PropTypes.any,
  lastSession: PropTypes.any,
  redirectToFormWithEmail: PropTypes.any
}

export default AuthMethods
